<template>
	<!-- 首页 左侧推广图表 -->
	<a-card :bordered="false" class="dashboard-bar-chart">
		<chart-bar :height="220" :data="barChartData"></chart-bar>
		<div class="card-title">
			<h6>推广获取收益</h6>
			<p>
				您当前是<span class="text-danger">{{ dealerData.levelName }}</span>
			</p>
		</div>
		<div class="card-content">
			<p>现在您有机会通过邀请更多的用户来赚取佣金,这是您获得额外收入的绝佳方式。只需花费一点时间和精力来推广您的链接或分享给朋友,就可以轻松获得更多的佣金。快来参与吧!</p>
		</div>
		<a-row class="card-footer" type="flex" justify="center" align="top">
			<a-col :span="6">
				<h4>{{ dealerData.direct }}</h4>
				<span>直推用户</span>
			</a-col>
			<a-col :span="6">
				<h4>{{ dealerData.indirect }}</h4>
				<span>间推用户</span>
			</a-col>
			<a-col :span="6">
				<h4>${{ dealerData.money.toFixed(2) }}</h4>
				<span>总佣金</span>
			</a-col>
			<a-col :span="6">
				<h4>{{ dealerData.levelText }}</h4>
			</a-col>
		</a-row>
	</a-card>
</template>

<script>
	import ChartBar from "../Charts/ChartBar"
	export default {
		components: {
			ChartBar
		},
		props: {
			dealerData: {
				type: Object,
				default: {}
			},
			userChart: {
				type: Object,
				default: () => {}
			}
		},
		data() {
			return {
				barChartData: {
					labels: [],
					datasets: [
						{
							label: "数量",
							backgroundColor: "#fff",
							borderWidth: 0,
							borderSkipped: false,
							borderRadius: 6,
							data: [],
							maxBarThickness: 20
						}
					]
				}
			}
		},
		watch: {
			userChart: {
				deep: true,
				handler(newValue, oldValue) {
					this.barChartData.labels = newValue.keys
					this.barChartData.datasets[0].data = newValue.values
				}
			}
		},
		created() {},
		methods: {}
	}
</script>
